<template>
  <ul class="requisites">
    <li class="requisites__item">
      <h4 class="requisites__subtitle">ИНН:</h4>
      <span class="requisites__text">{{ requisites?.inn }}</span>
    </li>
    <li class="requisites__item">
      <h4 class="requisites__subtitle">ОГРН:</h4>
      <span class="requisites__text">{{ requisites?.msrn }}</span>
    </li>
    <li class="requisites__item">
      <h4 class="requisites__subtitle">Наименование организации:</h4>
      <span class="requisites__text">{{ requisites?.organization_name }}</span>
    </li>
    <li class="requisites__item">
      <h4 class="requisites__subtitle">ОКВЭД:</h4>
      <span class="requisites__text">{{ requisites?.arctea }}</span>
    </li>
    <li class="requisites__item">
      <h4 class="requisites__subtitle">Юр. адрес организации:</h4>
      <address class="requisites__address">{{ requisites?.organization_legal_address }}</address>
    </li>
    <li class="requisites__item">
      <h4 class="requisites__subtitle">Руководитель организации:</h4>
      <span class="requisites__text">{{ requisites?.director }}</span>
    </li>
    <li class="requisites__item">
      <h4 class="requisites__subtitle">Почтовый адрес организации:</h4>
      <address class="requisites__text">{{ requisites?.organization_actual_address }}</address>
    </li>
    <li class="requisites__item">
      <h4 class="requisites__subtitle">Действующий по доверенности:</h4>
      <span class="requisites__text">{{ requisites?.trusted_person }}</span>
    </li>
  </ul>
</template>

<script>

export default {
  props: {
    requisites: {
      type: Object,
      required: false
    }
  }
}
</script>
