<template>
  <VRequisitesList
  :requisites="requisites"/>
</template>

<script>
import VRequisitesList from '@/components/profile/VRequisitesList'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { computed, onBeforeMount } from 'vue'

export default {
  setup () {
    const route = useRoute()
    const store = useStore()

    onBeforeMount(async () => {
      if (route.params.id) {
        await store.dispatch('user/setUser', route.params.id)
      }
    })

    const requisites = computed(() => {
      return store.getters['user/getUser']
    })

    return {
      requisites
    }
  },
  components: {
    VRequisitesList
  }
}
</script>
